@import url('https://fonts.googleapis.com/css2?family=Luckiest+Guy&family=Source+Sans+3:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

body, html {
  font-size: 10px;
}

.App {
  text-align: center;
}


body {
  background-color: #395062;
}

.Logo {
  text-align: left;
  position: absolute;
  top: 1rem;
  left: 1rem;
}

.Logo .name {
  font-family: Luckiest Guy;
  font-size: 10rem;
  line-height: 7rem;
  text-transform: uppercase;
  color: rgb(255, 255, 255);
}

.name span {
  display: block;

}

.name .last {
font-size: 8rem;
}

.name .tagLine {
  font-family: "Source Sans 3";
  font-size: 2rem;
  line-height: 1;
  color: #f17377;
}